import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import PhoneIcon from '@material-ui/icons/Phone'
import EmailIcon from '@material-ui/icons/Email'
import LocationImg from '../images/location.png'

const Contact = () => {
    return (
        <div>
            <Layout>
                <Seo title="Company" description="BricoBrowser associated with Ricta Technologies" />
                <section className="bg-bgColor h-screen">
                    <h3 className="sm:text-3xl text-2xl text-mainColor pt-4 mb-4">Contact Us </h3>
                    <hr className="horizontalLine"/>
                    <div className="grid grid-cols-1 lg:grid-cols-2 ">
                        <div className="lg:ml-8 mb-2 w-11/12 m-auto flex justify-center map-responsive">   
                            <img className="rounded-xl shadow-xl" src={LocationImg}/>
                            {/*
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5893.33242927121!2d26.03068154767513!3d44.431012483798355!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b201b594ed1db7%3A0x32d2b39b5f8e280e!2sStrada%20Lujerului%2C%20Bucure%C8%99ti%2C%20Rumunjska!5e1!3m2!1shr!2sba!4v1620922145638!5m2!1shr!2sba" width="560" height="450" style={{border:'0'}} allowFullScreen="true" loading="lazy"></iframe>
                            */}
                        </div>
                        <div className="flex flex-col justify-center items-left ml-8 my-4 mx-2 text-mainColor">
                            <div className="text-center md:text-left w-full lg:w-4/5 m-auto">
                                <p className=" sm:text-xl font-normal mb-2"><span className="font-bold">Ricta Technologies SRL</span></p>
                                <p className=" sm:text-xl font-normal mb-2"><span className="font-bold"><LocationOnIcon className="mr-1 md:mr-2"/>Address:</span>Bucharest, Romania </p>
                                <p className=" sm:text-xl font-normal mb-2"><span className="font-bold"><PhoneIcon  className="mr-1 md:mr-2"/>Phone Number:</span> +40 312297492</p>
                                <p className=" sm:text-xl font-normal mb-2"><span className="font-bold"> <EmailIcon  className="mr-1 md:mr-2"/>Email:</span> hello@bricobrowser.com</p>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </div>
    )

}
export default Contact